.navbar-component {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: var(--Generic-White);
    padding-left: 20px;
    align-items: center;
    column-gap: 12px;
    position: relative;

    .link-buttons {
        width: max-content;
        color: var(--CCA-Green-Dark);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (min-width:1024px) {
            padding: 8px 14px;
        }
    }

    .selected-menu {
        border-radius: 500px;
        background: var(--primary-focus);
    }

    .link-buttons:hover {
        border-radius: 500px;
        background: var(--primary-focus);
    }


    .link-menu-items {
        margin-left: 24px;
    }

    .logged-in-user {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        column-gap: 5px;
        padding-left: 8px;
        cursor: pointer;

        .user-avatar {
            height: 24px;
            width: 24px;

            @media (min-width:1250px) {
                height: 32px;
                width: auto;
            }
        }
    }

    .user-name {
        color: var(--CCA-Green-Dark);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.46px;
        padding: 3px 0px;
    }

    .menu-icon {
        width: 24px;
        height: auto;
    }

}