.module-card-component {
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    background-color: var(--Generic-White);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    @media (min-width : 769px) {
        flex-direction: row;
        border-radius: 24px;
    }
}