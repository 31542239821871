.topic-card-component {
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    background-color: var(--Generic-White);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);

    @media (min-width : 769px) {
        flex-direction: row;
        border-radius: 24px;
    }

    .topic-img {
        width: 100%;
        height: 240px;
        object-fit: cover;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        @media (min-width : 769px) {
            width: 423px;
            height: auto;
            border-top-left-radius: 24px;
            border-bottom-left-radius: 24px;
            border-top-right-radius: 0px;
        }
    }


    .topic-details {
        display: flex;
        flex-direction: column;
        padding: 16px 32px;
        background-color: var(--Generic-White);
        flex: 1;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        @media (min-width : 769px) {
            border-bottom-left-radius: 24px;
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px;
            padding: 36px 48px;
        }
    }

    .details-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .details-header-title {
            color: #4D4D4D;
            text-align: start;
            font-family: Inter;
            font-style: normal;
            font-size: 24px;
            font-weight: 600;
            line-height: 133.4%;

            @media (min-width : 769px) {
                font-size: 34px;
                line-height: 123.5%;
                letter-spacing: 0.25px;
            }
        }

        .learn-link {
            color: var(--CCA-Green-Dark);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
            letter-spacing: 0.15px;
            cursor: pointer;
        }
    }

    .detail-item {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.50);
        padding: 8px 0px;
        cursor: pointer;

        @media (min-width : 769px) {
            padding: 14px 0px;
        }
    }

    .link-detail-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .link-block {
        width: 100%;
    }

    .details-menu-items {
        display: flex;
        flex-direction: column;
    }

    .icon-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 12px;

        img {
            width: 24px;
            height: auto;
        }
    }

    .arrow-icon {
        width: 16px;
        height: auto;

        @media (min-width : 769px) {
            width: 25px;
            height: auto;
        }
    }

    .title-line {
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
    }
}