.module-section-component {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    margin-bottom: 25px;

    .section-title-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid black;
        padding-bottom: 5px;
    }

    .section-icon-box {
        display: flex;
        flex-direction: row;
        column-gap: 7px;
        cursor: pointer;

        .arrow-icons {
            height: 33px;
            width: auto;
            cursor: pointer;
        }

        .arrow {
            height: 33px;
            width: auto;
            transition: transform 0.5s ease-in-out;
        }

        .arrow.rotate {
            transform: rotate(-180deg);
        }
    }

    .course-list {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
    }

    .section-title {
        color: black;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 123.5%;
        letter-spacing: 0.25px;

        @media (min-width: 769px) {
            font-size: 34px;
        }
    }

    .green-border-button {
        border-radius: 500px;
        border: 1.5px solid var(--primary-focus);
        display: flex;
        padding: 8px 36px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--Generic-White);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        background-color: var(--CCA-Green-Dark);
        width: min-content;
        cursor: pointer;
    }

    .active-module {
        border-right: 5px solid transparent;
    }
}