.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 12px;

    .signup-form{
        margin-top: 20px;
    }

    .subtitle {
        color: var(--CCA-Green);
        padding: 12px 0px;
    }
 
    .nav-link {
        color: var(--CCA-Green);
    }

    .signup-page-email-input {
        border-radius: 4px;
        border: 1px solid #E5E5E5;
        background: var(--Generic-White);
    }

    .signup-page-email-input::placeholder {
        color: #A3A3A3;
    }

    .signup-page-email-input:focus {
        outline: none;
    }
  }
  