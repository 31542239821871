.explore-topic-card-component {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
    background-color: var(--Generic-White);
    cursor: pointer;

    .card-img {
        height: 225px;
        width: auto;
        object-fit: cover;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        object-fit: cover;
    }

    .card-content {
        padding: 16px 18px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .card-content .card-title {
        color: var(--CCA-Green-Dark);
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 133.4%;
        min-height: 64px;
    }

    .card-details {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .card-deails-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 12px;

        .span {
            color: #4D4D4D;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.15px;
        }
    }

    .card-deails-icon {
        width: 16px;
        height: 16px;
    }
}