.invalid-link-page {
  .title {
    margin-bottom: 0.5em;
    color: var(--CCA-Green-Dark);
  }

  .email-title {
    font-size: 16px;

    @media (min-width: 374px) {
      font-size: 19px;
    }

    @media (min-width: 426px) {
      font-size: 52px;
    }
  }
}