.date-and-time-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 18px;
    align-items: flex-start;

    .title-action-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .cca-date-picker-component {
        border-radius: 12px;
    }

    .MuiFormControl-root .MuiFormLabel-root {
        margin-top: -7px;
    }

    .MuiFormControl-root .MuiInputBase-root {
        height: 40px;
        background-color: var(--Generic-White);
    }

    .css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 12px;
        background-color: transparent;
    }

    .calender-input {
        background-color: var(--Generic-White);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        border: 1.5px solid var(--interactive-input);
        background: var(--Generic-White);
        outline: none;

        img {
            width: 20px;
            height: auto;
        }
    }

    .title-btn-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .green-bin-icon {
        width: 32px;
        height: auto;
        cursor: pointer;
    }

    input {
        border: none;
    }

    .input-grid {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 18px;

        .select-container {
            position: relative;
            display: inline-block;
            .custom-select {
                width: 300px;
                height: 40px;
                background-color: var(--Generic-White);
                border: 1px solid #ccc;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                cursor: pointer;
            }

            .custom-select:focus {
                outline: none;
                border-color: var(--interactive-input-focus);
                box-shadow: 0 0 3px var(--interactive-input-focus);
            }


            .clock-icon {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                pointer-events: none;
                color: #333;
                z-index: 2;
            }

            select {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background: transparent;
                padding-right: 30px;
                border: 1px solid #ccc;
                padding: 8px;
                border-radius: 4px;
                width: 300px;
                height: 40px;
                background-color: var(--Generic-White);
                font: inherit;
            }
        }
    }

}