.radio-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid black;
  border-radius: 50%;
  outline: none;
  position: relative;
  cursor: pointer;

  @media (min-width: 426px) {
    width: 20px;
    height: 20px;
  }

  @media (min-width: 800px) {
    width: 20px;
    height: 20px;
  }

  @media (min-width: 1300px) {
    width: 24px;
    height: 24px;
  }
}

.radio-input:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: black;
  width: 10px;
  height: 10px;

  @media (min-width: 426px) {
    width: 12px;
    height: 12px;
  }

  @media (min-width: 800px) {
    width: 12px;
    height: 12px;
  }

  @media (min-width: 1300px) {
    width: 16px;
    height: 16px;
  }
}

.label-text {
  flex: 1;
  font-size: 16px;
  margin-top: 2px;
  cursor: pointer;

  @media (min-width: 800px) {
    font-size: 18px;
  }
}
