.response-navbar-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--Generic-White);
    row-gap: 24px;
    position: absolute;
    z-index: 999;
    top: 56px;
    left: 0px;
    padding: 20px 0px;

    .menu-link-buttons {
        color: var(--CCA-Green-Dark);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 35px;
        padding-right: 15px;
    }

    .submenu {
        color: black;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        margin-left: 48px;

        @media (min-width : 350px) {
            margin-left: 48px;
        }

        .submenu-item {
            margin: 16px 24px;
        }
    }

    .link-menu-items {
        margin-left: 24px;
    }

    .user-section-response {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }
}