.user-info-page {
  .page-content {
    padding-top: 0px;
  }

  .responsive-form {
    width: 100%;
  }

  .page-content {
    height: auto;
  }

  .question-button-div {
    width: auto;
  }

  @media (max-width: 425px) {

    .form-grid,
    .form-flex {
      padding: 0 15px;
    }
  }
}