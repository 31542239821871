.module-sidebar-component {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-family: Inter;
    overflow-y: auto;
    width: auto;
    padding: 36px 15px;

    .modules-list {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .checked-icon-green {
        height: 32px;
        width: auto;
    }
}