.side-tabs-component {
  display: flex;
  flex-direction: row;
  row-gap: 5px;
  padding: 20px;
  height: 100%;
  font-family: Inter;

  @media (min-width: 600px) {
    flex-direction: column;
  }

  .tab-button {
    text-align: start;
    padding: 4px 9px;
    cursor: pointer;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    border-left: 3px solid var(--CCA-Grey);

    @media (min-width: 600px) {
      padding: 8px 18px;
      font-size: 18px;
      line-height: 31.12px;
      border-bottom: 3px solid transparent;
    }
  }
  .active-tab-button {
    text-align: start;
    border-bottom: 3px solid transparent;
    border-bottom: 3px solid var(--CCA-Maroon);
    background-color: var(--Maroon-Light);
    padding: 4px 9px;
    cursor: pointer;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: var(--CCA-Maroon);

    @media (min-width: 600px) {
      padding: 8px 18px;
      font-size: 18px;
      line-height: 31.12px;
      border-left: 3px solid var(--CCA-Maroon);
      border-bottom: 3px solid transparent;
    }
  }
}
