.single-journal-entry-component {
    display: flex;
    flex-direction: column;
    row-gap: 18px;

    .journal-ques-ans {
        display: flex;
        flex-direction: column;
    }

    .journal-ans {
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
    }

    .journal-title {
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
    }

    .journal-content {
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.3px;
    }

    .green-rounded-btn {
        padding: 10px 40px;
        margin-top: 20px;
        width: max-content;
        height: auto;
    }
}