.self-schedule-question-component {
  .subtitle {
    @media (max-width: 900px) {
      color: var(--CCA-Brown);
    }
  }

  .grid-col-two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: center;
    width: 85%;
    margin-top: 15px;
  }

  .grid-col-four {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 15px;
    align-items: flex-start;
    justify-items: center;
    text-align: center;
  }

  .grid_two_response {
    @media (max-width: 900px) {
      display: none;
    }
    font-weight: 600;
  }

  .que_col_response {
    @media (max-width: 900px) {
      flex-direction: column;
      display: flex;
      margin-bottom: 15px;
    }
  }

  .que_col_response .que_name {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .radio-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  @media (max-width: 900px) {
    .que_col_response .radio-buttons {
      flex-wrap: wrap;
      justify-content: flex-start;
      column-gap: 15px;
      row-gap: 10px;
    }
  }

  .que_col_response .radio-buttons .options {
    @media (max-width: 900px) {
      width: 44%;
      height: 45px;
      border: 1px solid var(--CCA-Brown);
      box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
      border-radius: 5px;
      padding: 7px 5px;
      align-items: center;
      background-color: var(--CCA-Light-Brown);
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .lable_remove {
    margin-right: auto;
    height: 60px;
    align-items: center;
    display: flex;
    flex: 1;

    @media (min-width: 901px) {
      display: none;
    }
  }

  .options {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 5px;
  }

  .additional-div {
    @media (max-width: 900px) {
      margin-top: 30px;
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .button-box {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 40px;
  }
}