.intro-content-component {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    border-bottom: 1px solid black;
}

.intro-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.module-desciption {
    color: black;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
}