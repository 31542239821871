.general-question-page {
  .general-question {
    @media (max-width: 828px) {
      margin-top: 5px;
      font-size: 14px;
    }

    color: var(--CCA-Green-Dark);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .general-que-margin {
    @media (max-width: 500px) {
      margin-top: 40px;
    }
  }

  .button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0px 40px;
  }
}