.toast {
  position: absolute;
  top: 12%;
  right: 5%;
  left: 5%;
  z-index: 999;
  background-color: #ff0000db;
  font-weight: 600;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.9;
  transition: opacity 0.5s ease;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toast-message {
  flex-grow: 1;
  margin-right: 10px;
}

.toast-close {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transform: scale(1.5); 
  line-height: 1; 
}

.toast-enter {
  opacity: 0;
}

.toast-enter-active {
  opacity: 0.9;
  transition: opacity 0.5s ease;
}

.toast-exit {
  opacity: 0.9;
}

.toast-exit-active {
  opacity: 0;
  transition: opacity 0.5s ease;
}

@media (min-width: 426px) {
  .toast {
    top: 20%;
    left: 25%;
    right: 25%;
  }
}

@media (min-width: 829px) {
  .toast {
    top: 18%;
    left: 5%;
    right: 5%;
  }
}

@media (min-width: 900px) {
  .toast {
    top: 18%;
    left: 30%;
    right: 30%;
  }
}

@media (min-width: 1024px) {
  .toast {
    top: 16%;
    left: 30%;
    right: 30%;
  }
}

@media (min-width: 1300px) {
  .toast {
    top: 16%;
    left: 35%;
    right: 35%;
  }
}
