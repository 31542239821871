.individual-support-page {
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    width: 100%;
    height: 100%;

    .support-side-tab {
        width: 20%;
        display: flex;
        flex-direction: column;
        row-gap: 25px;
    }

    .support-card-container {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .tab-options {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
    }

    .no-services-info {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;

        .sub-header {
            font-size: 40px;
            font-weight: 600;
        }

        .title {
            font-size: 16px;
            letter-spacing: 0.7px;
            color: var(--CCA-Green-Dark);

            @media(min-width: 1024px) {
                font-size: 32px;
            }
        }
    }
}