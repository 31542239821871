.hero-section-component {
    .hero-image {
        position: relative;
        padding: 0px 36px 36px 0px;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 10px;

        @media (min-width : 768px) {
            width: 50%;
        }
    }

    .hero-slider-conatiner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .hero-three-grid {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .hero-title {
        color: var(--Generic-White);
        text-align: right;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 133.4%;

        @media (min-width : 769px) {
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 116.7%;
        }
    }

    .hero-first-title {
        color: var(--Generic-White);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 133.4%;
        justify-self: end;
        width: 100%;
        text-align: end;

        @media (min-width : 769px) {
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 116.7%;
        }

        @media (min-width : 1024px) {
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 116.7%;
        }
    }

    .hero-button {
        display: flex;
        padding: 8px 22px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        border: none;
        color: var(--Generic-White);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        color: var(--Generic-White);
        background: var(--CCA-Green-Dark);
        cursor: pointer;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    }

    .button-box {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        cursor: pointer;
    }

    .hero-img-one {
        background-image: url('https://storage.googleapis.com/members_portal_static_images/homepage_image.png');
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 24px;
        height: 247px;

        @media (min-width : 769px) {
            height: 370px;
        }

        .hero-content {
            color: var(--Generic-White);
            text-align: right;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 133.4%;
        }
    }

    .center-content {
        padding: 36px 36px 36px 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 20px;
        height: 70%;

        .hero-title {
            text-align: left;

        }

        @media (min-width : 769px) {
            row-gap: 20px;
        }
    }

    .show-content {
        display: none;

        @media (min-width : 769px) {
            display: block;
        }
    }

    .hero-img-two {
        background-image: url('https://storage.googleapis.com/members_portal_static_images/hero-img-2.png');
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 24px;
        height: 247px;

        @media (min-width : 769px) {
            height: 370px;
        }

        h3,
        h2 {
            margin: 0;
        }

        .decorated-text {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 116.7%;
            text-decoration-line: underline;
            color: var(--Generic-White);

            @media (min-width : 769px) {
                font-size: 64px;
            }
        }
    }

    .hero-img-three {
        background-image: url('https://storage.googleapis.com/members_portal_static_images/Mental%20Health%20Resources.jpeg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 24px;
        height: 247px;

        @media (min-width : 769px) {
            height: 370px;
        }
    }

    .hero-third-title {
        color: var(--CCA-Green-Dark);
        text-align: start;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 133.4%;

        @media (min-width : 769px) {
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 116.7%;
            width: 60%;
        }
    }

    .hero-image-third {
        width: auto;
        flex-direction: column;
        justify-content: flex-end;
        column-gap: 20px;
        display: flex;
        align-items: center;
        margin-right: 2%;

        @media (min-width : 769px) {
            width: 50%;
            justify-content: flex-end;
            column-gap: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .slick-dots {
        position: absolute;
        top: 45%;
        left: 2%;
        padding: 0;
        margin: 0;
        width: auto !important;
    }

    .slick-dots li {
        display: block;
    }

    .slick-dots li.slick-active button:before {
        color: var(--CCA-Green-Dark) !important;
        opacity: 1 !important;
        font-size: 14px;
    }

    .slick-dots li button:before {
        color: var(--Generic-White) !important;
        opacity: 1 !important;
        font-size: 14px;
    }
}