.fixed-header-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: var(--CCA-Grey);
  position: relative;
}

.common-header {
  background: var(--Generic-White);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.smaller-fixed-header {
  padding: 8px 16px;
}

.fixed-header {
  padding: 21px 16px;
  background-color: var(--Generic-White);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--Generic-White);
  width: 100%;
  position: fixed;
  z-index: 1000;
}

.header-content-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--CCA-Grey);
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  margin-top: 60px;

  @media (min-width : 769px) {
    margin-top: 88px;
  }
}

.footer-div {
  padding-top: 20px;
  background-color: var(--CCA-Grey);
}

.avatar {
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.error-message {
  position: absolute;
  bottom: 20%;
  right: 0;
  left: 0;
}

.fixed-header-logo {
  width: 118px;
  height: auto;
  cursor: pointer;
}

.header-nav-link {
  color: var(--CCA-Maroon);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: underline;
  display: none;
}

.prt-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 18px;
  cursor: pointer;
}

.user-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}

.user-div-text {
  color: var(--CCA-Maroon);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.nav-link-div {
  display: flex;
  flex-direction: column;
}

.menu-icon {
  width: 41px;
  height: 41px;
}

.header-title {
  display: none;
}

.logout-button {
  display: flex;
  width: 138px;
  height: 44px;
  padding-top: 14px 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid var(--Neutral-200);
  background: var(--CCA-Maroon);
  color: var(--Generic-White);
}

.prt-text {
  color: var(--CCA-Maroon);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.prt-logout-btn {
  border-radius: 50px;
  width: 131px;
  border: 1px solid var(--CCA-Maroon);
  padding: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.7px;
  text-align: center;
  background-color: var(--Generic-White);
  color: var(--CCA-Maroon);
  cursor: pointer;
}

.sign-in-btn {
  border: solid 1.5px var(--CCA-Green);
  padding: 6px 16px;
  color: var(--CCA-Green);
  font-size: 15px;
  font-family: Inter;
  font-weight: 500;
  border-radius: 28px;
  background-color: var(--Generic-White);

  @media (min-width: 426px) {
    padding: 8px 18px;
    line-height: 22px;
    border-radius: 30px;
    cursor: pointer;
  }
}