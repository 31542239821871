.increment-decrement-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;

    .combo-button {
        display: flex;
        flex-direction: row;
        border-radius: 25px;
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.06);
    }

    .button {
        flex: 1;
        display: flex;
        justify-content: center;
        font-size: 24px;
        color: white;
        background: var(--primary-main, #2B7272);
        border: none;
        cursor: pointer;
        height: 40px;
    }

    .decrement {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        font-size: 32px;
    }

    .increment {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        font-size: 32px;
    }

    .count {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        color: #333;
        background-color: #fff;
        width: 100px;
    }
}