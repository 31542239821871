.member-portal-header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--Generic-White);
    width: 100%;
    padding: 24px;

    @media (min-width : 1024px) {
        max-width: 1000px;
    }

    @media (min-width : 1440px) {
        max-width: 1250px;
    }

    .left-logo-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 18px;
        width: 100%;
        cursor: pointer;
    }

    .left-logo-section {
        height: 24px;
        width: auto;

        @media (min-width : 426px) {
            height: 38px;
        }
    }

    .right-alinged-btns {
        display: flex;
        flex-direction: row;
        column-gap: 6px;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        @media (min-width: 426px) {
            column-gap: 24px;
            width: auto;
        }
    }


    .info-text {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: var(--CCA-Green);

        @media (min-width:426px) {
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.46px;
            cursor: pointer;
        }
    }

    .client-logo {
        border-left: #7A7774 solid 1px;
        height: 100%;
        color: var(--CCA-Green-Dark);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        letter-spacing: 0.15px;
        padding-left: 10px;
        display: flex;
        align-items: center;

        @media (min-width:426px) {
            font-size: 18px;
        }
    }

    .menu-icon {
        height: 24px;
        width: auto;
        display: block;

        @media (min-width : 769px) {
            display: none;
        }
    }

    .nav-bar-response {
        display: none;

        @media (min-width:769px) {
            display: block;
        }
    }
}