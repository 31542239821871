.footer-component {
  padding: 5px 0px;
  width: 100%;
  height: 30px;
  background-color: var(--Generic-White);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    float: right;
    color: var(--CCA-Maroon);
    font-weight: 600;
    margin-right: 2%;
    text-decoration: underline;
    cursor: pointer;
  }
  
}

