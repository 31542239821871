.progressbar-component {
  width: 320px;
  height: 10px;
  border-radius: 10px;
  bottom: 50px;
  margin-bottom: 30px;

  @media (min-width: 550px) {
    width: 500px;
  }
}
