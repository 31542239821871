.article-card-content-component {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    box-shadow: 2px 2px 4px 0px #0000001A;

    @media (min-width : 767px) {
        width: calc(50% - 10px);
    }

    @media (min-width : 1024px) {
        width: calc(25% - 10px);
    }

    .article-card-component {
        border-radius: 24px;
        background-color: var(--Generic-White);

        .article-card-img {
            height: 280px;
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
        }

        .article-card-content {
            background-color: var(--Generic-White);
            padding: 16px;
            border-bottom-left-radius: 24px;
            border-bottom-right-radius: 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 12px;
            width: auto;
        }
    }

    .article-card-name {
        color: #4D4D4D;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 133.4%;
        text-align: start;
        width: 100%;
        max-height: calc(1.334em * 2);
        min-height: calc(1.334em * 2);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .article-card-content {
        color: #737373;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
    }

    .article-card-description {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        color: #737373;
        margin-top: 10px;
        max-height: calc(1.334em * 3);
        min-height: calc(1.334em * 3);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .card-link {
        color: black;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        text-decoration-line: underline;
    }
}