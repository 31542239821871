.questionnaire-score-component {
    display: flex;
    flex-direction: column;
    row-gap: 18px;

    .module-box {
        margin-top: 24px;
    }

    .left-side-img {
        @media (min-width : 769px) {
            width: 350px;
            height: auto;
        }
    }

    .margin-title {
        margin-top: 15px;
    }

    .green-rounded-btn {
        padding: 10px 40px;
        margin-top: 10px;
        width: max-content;
        height: auto;
    }
}