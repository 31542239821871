.course-module-component {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    width: 100%;
    justify-content: flex-start;
    position: relative;

    .module-title-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .module-title-header {
            color: black;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
            letter-spacing: 0.15px;
        }

        .module-header-icon {
            width: 28px;
            height: 28px;
            transition: transform 0.3s ease;
        }

        .module-header-icon.rotate {
            transform: rotate(-90deg);
        }
    }

    .module-course-list {
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
        display: flex;
        flex-direction: column;
        row-gap: 24px;
    }

    .module-course-list.animate-in {
        opacity: 1;
        transform: translateY(0);
    }

    .module-course-list.animate-out {
        opacity: 0;
        transform: translateY(-10px);
    }

    .course-item-title {
        color: #4D4D4D;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
    }

    .course-list-component {
        column-gap: 12px;
    }

    .course-icon-box {
        width: 18px;
        height: 18px;
        padding: 6px;
    }
}