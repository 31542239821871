.terms-of-service-page {
    background: var(--CCA-Grey);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;
    padding: 5%;
    font-family: "Inter";

    em {
        margin-right: 5px;
        margin-left: 5px;
    }
}