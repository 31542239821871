.conversation-list-component {
  display: flex;
  flex-direction: column;
  background: var(--Generic-White);
  margin-top: 32px;
  width: 90%;
  border-radius: 10px;

  @media (min-width: 900px) {
    width: 850px;
  }

  .conversation-list {
    display: flex;
    width: 100%;
  }

  .ticket-div {
    align-items: center;
    padding: 16px;
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 840px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .ticket-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .ticket-date {
    color: var(--neutral-900);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: bolder;
    line-height: 20px;
    margin-left: 12px;
  }

  .ticket-status {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    border: 1px solid var(--CCA-Green);
    color: var(--CCA-Green);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 24px;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    width: 100%;

    @media (min-width: 840px) {
      flex-direction: row;
    }
  }
}
