.form-container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  row-gap: 32px;
}
.form-container input {
  padding: 8px 12px;
  align-items: center;
}
.form-container select {
  padding: 8px 12px;
  align-items: center;
}
.form-grid {
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @media (min-width: 768px) {
    width: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 22px;
  }
}

.form-flex {
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @media (min-width: 768px) {
    width: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 22px;
  }
}
