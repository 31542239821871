.privacy-policy-page {
  background: var(--CCA-Grey);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 20px;
  padding: 5%;
  font-family: "Inter";
  
  .policy-title {
    border-bottom: 1px solid silver;
    color: var(--CCA-Green);
    font-size: 24px;
    font-weight: 600;
    font-family: "Inter";
    padding-bottom: 3px;
  }

  .policy-subtitle {
    color: var(--Neutral-700);
    font-size: 18px;
    font-weight: 600;
    font-family: "Inter";
    margin-top: 15px;
  }

  .text-content {
    font-size: 14px;
    font-family: "Inter";
    letter-spacing: 0.2px;
  }

  .bold-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
}
