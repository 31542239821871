.module-page {
    cursor: pointer;

    .module-cover-image {
        width: 100%;
        height: 150px;
        object-fit: cover;

        @media(min-width:769px) {
            height: 425px;
        }
    }

    .right-content-details {
        background-color: transparent;
    }
}