.journal-entry-component {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    row-gap: 12px;
    border-bottom: 1px solid black;
    width: 100%;

    .title-button-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .date-n-time {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.02px;
        letter-spacing: 0.17px;
        text-align: left;
        color: gray;
    }

    .icons-section {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .green-bin-icon {
            width: auto;
            height: 40px;
            cursor: pointer;
        }
    }

    .ques-ans {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }

    .count-ques-ans {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .opacity {
        opacity: 0.5;
        display: flex;
        flex-direction: row;
        column-gap: 12px;
    }

    .answers {
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
    }

    .depression-severity {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 15px;
    }

    .journal-que-ans {
        width: 100%;
    }

    .content-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        width: 100%;
    }

    .journal-header {
        width: 100%;
        margin-top: 15px;
    }

    .journal-question {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        font-weight: 600;
    }

    .green-rounded-btn {
        padding: 10px 40px;
        margin-top: 20px;
        width: max-content;
        height: auto;
    }
}