.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 650px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  justify-content: center;
}

.card {
  background-color: var(--CCA-Light-Brown);
  padding: 25px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--CCA-Brown);
  border-radius: 5px;
  font-weight: 600;
  font-family: Inter;
  text-align: center;
  cursor: pointer;
  height: 20px;
}
