.services-check-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  flex: 1;

  .service-button {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 25px;

    @media (min-width: 700px) {
      flex-direction: row;
      column-gap: 10px;
    }
  }

  .service-check-button {
    display: flex;
    width: 100%;
    height: 44px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--CCA-Green);
    border: none !important;
    color: var(--Generic-White);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    @media (min-width: 700px) {
      width: 186px;
      cursor: pointer;
    }
  }

  .service-check-button-disable {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #cccccc;
    border: none !important;
    color: var(--Generic-White);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    cursor: not-allowed;

    @media (min-width: 700px) {
      width: 186px;
      cursor: pointer;
    }
  }

  .message {
    font-size: 18px;
    font-weight: 600;
    font-family: Inter;
    color: var(--CCA-Maroon);
    margin-top: 15px;
    margin-bottom: 25px;
  }
}
