.worklife-req-form {
    width: 100%;
    padding-top: 30px;

    .form-container {
        @media (max-width : 425px) {
            width: 90%
        }
    }

    .question-button-yes {
        background-color: var(--CCA-Maroon);
        margin-top: 20px;
    }

    .optional-text {
        font-size: 14px;
        font-weight: 600;
        font-family: Inter;
        color: var(--neutral-500);
    }

    .button-box {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 40px;
        padding-bottom: 40px;
    }
}