@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@500&family=Roboto&display=swap");
@import "./colors.css";

.container {
  max-width: 94%;
  width: 100%;
  margin: 36px auto;

  @media (min-width : 550px) {
    max-width: 400px;
  }

  @media (min-width : 728px) {
    max-width: 600px;
  }

  @media (min-width : 1024px) {
    max-width: 1000px;
  }

  @media (min-width : 1440px) {
    max-width: 1250px;
  }

}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--CCA-Grey);
  padding-top: 50px;
  padding-bottom: 20px;
}

.page-center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex: 1 1;
  background: var(--CCA-Grey);
}

.page-content {
  display: flex;
  flex-direction: column;
  background: var(--CCA-Grey);
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;

  @media (min-width: 426px) {
    .page-content {
      padding-top: 50px;
    }
  }
}

.title {
  width: auto;
  color: var(--neutral-800);
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.02em;
  padding: 0 20px;

  @media (min-width: 800px) {
    max-width: 768px;
    font-size: 52px;
    line-height: 1.1;
    margin-top: 12px;
  }
}

.subtitle {
  color: var(--CCA-Green);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 15px 0;
}

.sub-title {
  color: var(--CCA-Brown);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px 0;
}

.input-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.topic-title {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;

  @media (min-width : 769px) {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
  }
}

.input-div label {
  color: var(--neutral-900);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
}

.input-div-select {
  height: 35px;
  background-color: white;
  font-family: Inter;
  font-size: 14px;
  border-radius: 2px;

  @media (min-width: 800px) {
    font-size: 16px;
    line-height: 1.2;
  }
}

.nav-link {
  color: var(--CCA-Green-Dark);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 20px;
  padding-bottom: 40px;
  font-family: Inter;
}

.large-green-button {
  width: auto;
  padding: 14px 20px;
  border-radius: 8px !important;
  background: var(--CCA-Green);
  color: var(--Generic-White);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  border: none !important;

  @media (min-width: 426px) {
    width: 384px;
  }
}

.large-green-button-disabled {
  width: auto;
  padding: 14px 20px;
  border-radius: 8px !important;
  background: #cccccc;
  color: var(--Generic-White);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: not-allowed;
  border: none !important;

  @media (min-width: 426px) {
    width: 384px;
  }
}

.success {
  font-size: 16px;
  color: rgb(9, 183, 9);
  font-weight: 600;
  font-family: Inter;
}

.question-button-no {
  display: flex;
  width: 75px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none !important;
  color: var(--Generic-White);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  background: var(--CCA-Maroon);
  cursor: pointer;

  @media (min-width: 426px) {
    width: 134px;
  }
}

.question-button-yes {
  display: flex;
  width: 75px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--CCA-Green);
  border: none !important;
  color: var(--Generic-White);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;

  @media (min-width: 426px) {
    width: 134px;
  }
}

.question-button-div {
  display: inline-flex;
  align-items: center;
  width: 300px;
  gap: 12px;
  margin: 34px 0px;
}

.view-button {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--CCA-Green);
  color: var(--Generic-White);
  text-align: justify;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border: none !important;
  cursor: pointer;
  width: 100%;
}

.select-menu {
  width: 300px;
  background-color: var(--Generic-White);
  white-space: normal;
  margin-bottom: 30px;

  @media (min-width: 500px) {
    width: 350px;
  }
}

.error {
  font-size: 0.9em;
  color: #f34;
  text-align: center;
  margin-top: 8px;
  font-weight: 600;
}

.show-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hide-div {
  display: none;
}

.margin-content {
  margin-top: 50px;
}

.loader-container {
  width: 100%;
  height: 100vh;
  background-color: var(--CCA-Grey);
}

.assign-button {
  padding: 10px 36px 10px 36px;
  border-radius: 50px;
  border: 2px solid var(--CCA-Green);
  background-color: var(--CCA-Green);
  color: var(--Generic-White);
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  cursor: pointer;
}

.dialog-buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
  column-gap: 10px;
  width: 100%;
}

.light-green-button {
  width: max-content;
  padding: 10px 36px;
  gap: 10px;
  border-radius: 50px;
  border: 2px solid #71BB9D;
  background: #71BB9D;
  color: var(--Generic-White);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.add-button {
  width: max-content;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 15px;
}

.green-rounded-btn {
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  align-self: stretch;
  border-radius: 500px;
  background: var(--CCA-Green);
  color: var(--Generic-White);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: none;
  height: 42px;
  width: 141px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
}


.disabled-rounded-btn {
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 500px;
  background: #cccccc;
  color: var(--Generic-White);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: none;
  height: 42px;
  width: 141px;
  cursor: not-allowed;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
}

.green-border-button {
  border-radius: 500px;
  border: 1.5px solid var(--primary-focus);
  display: flex;
  padding: 8px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--CCA-Green-Dark);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  background-color: transparent;
  height: 42px;
  width: 141px;
  cursor: pointer;
}

.disable-border-button {
  border-radius: 500px;
  border: 1.5px solid var(--primary-focus);
  display: flex;
  padding: 8px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #D3D3D3;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  background-color: transparent;
  height: 42px;
  width: 141px;
  cursor: not-allowed;
}

.no-underline {
  text-decoration: none;
  color: inherit;
}

.module-common-title {
  color: black;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 123.5%;
  letter-spacing: 0.25px;
}

.input-title {
  color: black;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.questionnarie-content {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

@media (max-width: 828px) {
  .emergency-disclaimer-component {
    font-size: 30px;
    line-height: 36px;
    padding-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .emergency-disclaimer-component {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (max-width: 426px) {
  .emergency-disclaimer-component {
    font-size: 28px;
    line-height: 40px;
  }

  .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    max-height: 60px !important;
  }

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    max-height: 60px !important;
  }
}