.member-signup-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    row-gap: 25px;
    background-color: var(-CCA-Grey);

    .title {
        padding-top: 30px;
    }

    .signup-form {
        margin-top: 0px;
    }

    .subtitle {
        color: var(--CCA-Green);
    }

    .nav-link {
        color: var(--CCA-Green);
    }

    .signup-page-input,
    .signup-page-email-input {
        border-radius: 4px;
        border: 1px solid #E5E5E5;
        background: var(--Generic-White);
    }

    .signup-page-input::placeholder {
        color: #A3A3A3;
    }

    .signup-page-email-input::placeholder {
        color: #A3A3A3;
    }

    .error-outline {
        outline-color: red;
        outline-width: 1px;
        outline-style: solid;
    }

    .invitaion-msg {
        font-size: 24px;
        color: var(--CCA-Green-Dark);
        font-weight: 600;
        font-family: Inter;
        text-align: center;
        width: 90%;

        @media (min-width:769px) {
            font-size: 32px;
        }
    }
}