.lessons-list-component {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    padding-right: 5px;
    cursor: pointer;

    .checked-icon-green {
        height: 30px;
        width: auto;
    }

    .course-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 8px;
    }

    .course-item-title {
        color: #4D4D4D;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        letter-spacing: 0.15px;
        padding-right: 5px;
    }

    .course-item-duration {
        color: #4D4D4D;
        text-align: right;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
    }
}

.active-module {
    border-right: 5px solid var(--CCA-Green-Dark);
    margin-right: -15px;
}