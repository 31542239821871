.chat-page {
  width: 100%;
  height: 100%;

  .chat-div {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 70px;
  }
  .chat-input {
    flex: 1;
    min-height: 42px !important;
    font-size: 20px;
    font-family: Inter;
    padding-left: 10px;
    margin: 0px;

    &:focus {
      outline: none !important;
    }

    @media (min-width: 426px) {
      margin-left: 20px !important;
    }
  }

  .chat-footer {
    position: fixed;
    bottom: 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background: white;
    padding: 0px 0px;

    > div {
      font-family: Inter !important;
      margin-left: 5px;
      font-size: 16px !important;
      padding: 5px !important;

      @media (min-width: 426px) {
        max-height: 100px !important;
      }
    }

    textarea {
      padding-left: 5px !important;
      max-height: 50px !important;
      overflow-y: auto !important;

      @media (min-width: 426px) {
        padding-left: 10px !important;
        max-height: 100px !important;
        overflow-y: auto !important;
      }
    }

    @media (min-width: 426px) {
      padding: 15px 0px;
    }
  }

  .messags-box {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px silver solid !important;
    margin-left: 25px;
    align-items: flex-start;
  }
  .message-list {
    display: flex;
    flex-direction: column;
    background: var(--CCA-Grey);
    height: 100%;
    overflow: auto;
    padding-bottom: 20px;
  }
  .chat-message {
    font-size: 16px;
    justify-content: center;
    margin: 10px 5px;
    font-weight: 600;
    font-family: Inter;
    color: var(--CCA-Maroon);
  }
  .latest-message {
    color: #1da605c2;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    margin: 10px 5px;
  }
  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px white solid;

    @media (min-width: 426px) {
      width: 55px;
      height: 55px;
    }
  }

  .msg-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-info-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .user-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
  }
  .time-text {
    font-size: 12px;
    margin-right: 15px;
  }
  .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .sender {
    color: var(--CCA-Green);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 5px;
  }
  .receiver {
    color: var(--CCA-Maroon);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 5px;
  }
  .button-set {
    display: flex;
    flex-direction: row;
    column-gap: 5px;

    @media (min-width: 426px) {
      column-gap: 5px;
      margin-right: 10px;
      padding-right: 10px;
    }
  }

  .respons-text {
    display: none;

    @media (min-width: 426px) {
      display: block;
    }
  }

  .respons-icon img {
    display: block;
    height: 20px;
    width: auto;

    @media (min-width: 426px) {
      display: none;
    }
  }

  .send-button {
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--CCA-Green);
    border: none !important;
    color: var(--Generic-White);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-left: -5px;
    cursor: pointer;

    @media (min-width: 426px) {
      width: 134px;
      margin-left: 0px;
    }
  }

  .send-button-disable {
    display: flex;
    width: 44px;
    height: 44px;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #cccccc;
    border: none !important;
    color: var(--Generic-White);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-left: -5px;
    cursor: not-allowed;

    @media (min-width: 426px) {
      width: 134px;
      margin-left: 0px;
    }
  }
}
