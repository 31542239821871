.schedule-table-component {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    position: relative;

    table {
        border-collapse: inherit;
        margin-top: 25px;
    }

    .table-body {
        border: 1.5px solid black;
        border-top: transparent;
    }

    .table-head {
        background-color: var(--CCA-Green-Dark);
        color: #FFFFFF;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        border: solid 1px var(--CCA-Green-Dark);
    }

    .day-header {
        padding: 10px;
        text-align: center;
        width: 100px;
        color: var(--Generic-White);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        letter-spacing: 0.15px;
    }

    .time-slot-header {
        padding: 10px;
        border: 1px solid #E0E0E0;
        width: min-content;
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 143%;
        letter-spacing: 0.17px;
    }

    .time-slot {
        padding: 10px;
        border: 1px solid #E0E0E0;
        text-align: center;
        cursor: pointer;
        color: var(--Black, #000);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 166%;
        letter-spacing: 0.4px;
        span {
            display: block;
            margin-bottom: 4px; 
        }
    }

    .day-title {
        padding: 0;
    }
}

.cell-tooltip {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    background-color: var(--Generic-White);
    padding: 15px;
    border-radius: 12px;
    max-width: 300px;
}

.day-info {
    color: var(--primary-main, #2B7272);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.tooltip-content {
    font-size: 16px;
    font-weight: 400;
    font-style: Inter;
    line-height: normal;
    color: black;
}

.MuiTooltip-tooltip {
    padding: 0px;
    background-color: transparent !important    ;
}