.faq-header-component {
    width: 100%;
    height: 312px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 18px;
    background-image: url('https://storage.googleapis.com/members_portal_static_images/header-faq-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media(min-width : 769px) {
        background-image: url('https://storage.googleapis.com/members_portal_static_images/header-faq-bg-long.png');
    }

    .title {
        font-size: 34px;
        margin: 0px;

        @media(min-width : 769px) {
            font-size: 60px;
        }
    }

    .faq-header-subtitle {
        max-width: 95%;
        color: #737373;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        letter-spacing: 0.15px;

        @media(min-width : 769px) {
            font-size: 18px;
            max-width: 50%;
        }
    }
}