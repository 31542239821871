.success-message-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 20px;


  .title {
    color: var(--CCA-Maroon);
    font-size: 24px;

    @media (min-width: 426px) {
      font-size: 28px;
    }

    @media (min-width: 1024px) {
      font-size: 52px;
    }
  }

  .message-content {
    font-size: 30px;
    font-weight: 600;
    font-family: Inter;
    font-style: italic;
    width: 60%;
  }

  .highlighted-text {
    font-size: 30px;
    font-weight: 600;
    font-family: Inter;
    font-style: italic;
    color: var(--CCA-Maroon);
  }

  .question-button-yes {
    margin-top: 15px;
    padding: 0 20px;
    width: auto;
  }
}