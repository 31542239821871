.article-page {
    display: flex;
    flex-direction: column;

    .media-title {
        font-family: Inter;
        font-size: 34px;
        font-weight: 600;
        line-height: 41.99px;
        letter-spacing: 0.25px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: var(--CCA-Green-Dark);
    }

    .content-columns {
        display: flex;
        width: 100%;
        flex-direction: row;
        column-gap: 24px;
    }

    .body-section {
        width: 70%;
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        img {
            width: auto;
            height: 400px;
            object-fit: cover;

            @media (min-width : 1300px) {
                height: 600px;
            }
        }
    }

    .src-section {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
    }

    .src-title {
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 32.02px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    .src-desc {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-align: left;
    }

    .src-links-list {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }

    .src-links {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        margin: 0 !important;
        padding: 0 !important;

        ul {
            padding-inline-start: 15px;
            margin: 0px;
            a {
                color: black;
            }
        }
    }

    .side-details {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
    }

    .resource-section {
        display: flex;
        flex-direction: column;
    }
}