.faq-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .questions-container {
        display: flex;
        flex-direction: column;
        max-width: 95%;
        min-width: 95%;

        @media (min-width:769px) {
            max-width: 70%;
            min-width: 70%;
        }
    }

    .question-section {
        display: flex;
        flex-direction: column;
        padding: 18px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        cursor: pointer;
    }

    .expandable-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .arrow-icons {
            height: 33px;
            width: auto;
            cursor: pointer;
        }

        .arrow {
            height: 33px;
            width: auto;
            transition: transform 0.5s ease-in-out;
        }

        .arrow.rotate {
            transform: rotate(-180deg);
        }
    }

    .question-title {
        color: black;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        letter-spacing: 0.15px;
        width: 100%;
    }

    .question-answer {
        width: 100%;
        color: rgba(0, 0, 0, 0.75);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
    }

    .list-section {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 10px;
    }
}