.charted-journal-component {
    display: flex;
    flex-direction: column;
    row-gap: 18px;

    .answer {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-align: left;
    }

    .date-n-time {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.02px;
        letter-spacing: 0.17px;
        text-align: left;
        color: gray;
    }

    .title-button-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .icons-section {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .green-bin-icon {
            width: auto;
            height: 40px;
            cursor: pointer;
        }
    }

    .journal-question {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        font-weight: 600;
    }

    .ques-ans {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin: 20px 0px;
    }

    .margin-inputs {
        margin: 20px 0px;
    }

    .count-ques-ans {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .green-rounded-btn {
        padding: 10px 40px;
        margin-top: 20px;
        width: max-content;
        height: auto;
    }
}