.account-info-page {
  display: flex;
  flex-direction: column;
  background: var(--CCA-Grey);
  height: 100%;
  width: 100%;

  .topic-title {
    text-align: center;
    margin-top: 30px;
  }

  .page-container {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .cards-conatiner {
    @media (min-width: 426px) {
      padding-left: 0%;
    }
  }

  .service-cards-div {
    @media (min-width: 1024px) {
      width: 872px;
    }
  }

  .no-conversation {
    text-align: center;
    width: 50%;
    background-color: var(--Generic-White);
    margin-top: 25px;
    padding: 15px;
    font-family: Inter;
    font-size: large;
    font-weight: 500;
  }

  .service-component {
    justify-content: center;
  }

  .grid-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    @media (min-width: 600px) {
      flex-direction: row;
    }
  }
}