.right-content-details {
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    background-color: var(--Generic-White);
    flex: 1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    row-gap: 20px;

    @media (min-width : 769px) {
        border-bottom-left-radius: 24px;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        padding: 36px 48px;
    }
}

.detail-item:nth-of-type(1) {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.left-side-img {
    width: 100%;
    height: 240px;
    border-top-left-radius: 12px;
    object-fit: cover;
    border-top-right-radius: 12px;

    @media (min-width : 769px) {
        width: 330px;
        height: auto;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        border-top-right-radius: 0px;
        object-fit: cover;
    }
}