.meet-coach-page {
  .coach-title {
    width: auto;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: -0.1px;
    text-align: center;
    font-family: Inter;
    margin-inline: 15px;
    color: var(--neutral-800);

    @media (min-width: 800px) {
      width: 768px;
      font-size: 30px;
      font-style: normal;
      line-height: 34px;
    }
  }

  .questionbtn_response_desktop {
    display: none;

    @media (min-width: 800px) {
      margin: 30px 0px 50px;
      display: block;
    }
  }

  .button-box {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 10px 0px;
  }

  .coach-intro {
    margin: 24px 0px;
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 12px;
    align-items: center;
    border-radius: 6px;
    background: #fff;
    gap: 30px;

    @media (min-width: 800px) {
      width: 800px;
      flex-direction: row;
    }
  }

  .coach-avatar {
    width: auto;
    height: 210px;
    border-radius: 6px;
  }

  .coach-image {
    width: auto;
    height: 217px;
    border-radius: 6px;
  }

  .coach-info {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    @media (min-width: 800px) {
      text-align: start;
    }
  }

  .coach-info-header {
    display: flex;
    flex-direction: column;
  }

  .coach-name {
    color: #171717;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .coach-designation {
    color: var(--neutral-500);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .details {
    color: var(--neutral-500);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
  }

  .linkedIn-logo {
    width: 20px;
    height: 20px;
  }

  .service-response {
    width: 90%;
    color: var(--Neutral-700);
    font-family: Inter;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-bottom: 10px;

    @media (min-width: 800px) {
      width: 60%;
      margin-bottom: 10px;
    }
  }

  .questionbtn_response {
    @media (min-width: 800px) {
      display: none;
    }
  }
}
