.request-form-page {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 30px;

  .radio-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .radio-group label {
    margin-right: 10px;
    font-family: Inter;
  }

  .question-label {
    color: var(--neutral-900);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  .radio-inline {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-family: Inter;
  }

  .checkbox-inline {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-family: Inter;
  }

  .responsive-form {
    width: 100%;
  }

  .form-container {
    margin-top: 0px;

    @media (min-width: 426px) {
      align-items: center;
    }
  }

  .page-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .error {
    margin-top: 15px;
  }

  .margin-content {
    margin-top: 25px;
  }

  @media (max-width: 425px) {
    .form-grid {
      padding: 0 20px;
      row-gap: 10px;
    }
  }

  .button-box {
    margin-top: 40px;
  }
}