.lesson-page {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
    height: 100%;

    .border-content {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid black;
    }
}