.support-card-component {
    border-radius: 24px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
    height: 540px;
    margin-bottom: 10px;
    background-color: var(--Generic-White);

    @media (min-width: 1024px) and (max-width: 1200px) {
        height: 600px;
    }
    
    @media (max-width: 320px) {
        min-width: 200px;
    }
    .support-card-img {
        height: 280px;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
    }

    .support-card-content {
        background-color: var(--Generic-White);
        padding: 16px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        display: flex;
        flex-direction: column;
        height: 225px;
        justify-content: space-between;
        row-gap: 12px;
        width: auto;

        @media (min-width: 1024px) and (max-width: 1200px) {
            height: 285px;
        }
    }

    .support-card-title {
        color: #4D4D4D;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 132.0%;
        letter-spacing: -0.48px;
    }

    .support-description {
        color: #737373;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }

    .support-button {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 500px;
        color: var(--Generic-White);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        border: none;
    }

    .active-button {
        background: var(--CCA-Green);
        cursor: pointer;
    }

    .inactive-button {
        background: #D3D3D3;
        cursor: not-allowed;
    }
}