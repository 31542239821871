.topic-page {
    display: flex;
    flex-direction: column;
    width: 100%;

    .container {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    .cover-image {
        width: auto;
        height: 150px;
        object-fit: cover;

        @media(min-width:769px) {
            height: 400px;
        }
    }

    .page-header-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 12px;

        @media(min-width: 769px) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    .page-title {
        color: var(--CCA-Green-Dark);
        font-family: Inter;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 123.5%;
        letter-spacing: 0.25px;

        @media(min-width: 769px) {
            line-height: 120%;
            font-size: 48px;
            letter-spacing: -0.5px;
            width: 100%;
        }
    }

    .health-page-content {
        color: black;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
    }

    .right-section {
        display: flex;
        flex-direction: column;
        align-self: center;
        row-gap: 18px;
        width: 100%;
    }

    .action-buttons-grid {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .action-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
    }

    .mini-icons {
        width: 20px;
        height: auto;
    }

    .cards-container {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        @media (min-width:769px) {
            margin-top: 64px;
        }
    }

    .content-desc-component {
        margin-inline: 0;
    }

    .right-content-details {
        padding: 15px;

        @media (min-width:769px) {
            padding: 35px;
        }
    }

    .sub-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        column-gap: 20px;

        @media (min-width: 769px) {
            margin-top: 64px;
        }

        .sub-header-icon {
            width: auto;
            height: 36px;

            @media (min-width: 769px) {
                height: 42px;
            }
        }

        .sub-header-text {
            color: black;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 116.7%;

            @media (min-width: 769px) {
                font-size: 42px;
            }
        }
    }

    .article-card-grid {
        display: flex;
        row-gap: 25px;
        column-gap: 12px;
        flex-wrap: wrap;
        margin-top: 36px;
    }

    .resources-section {
        @media (min-width: 769px) {
            margin-top: 64px;
        }
    }
}