.user-profile-menu-component {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 8px 36px;
    background-color: var(--Generic-White);
    border-radius: 12px;

    @media(min-width : 769px) {
        position: absolute;
        z-index: 999;
        top: 52px;
        right: -10px;
        padding: 12px 12px;
    }

    .menu-options {
        color: var(--CCA-Green-Dark);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        cursor: pointer;
    }
}