.content-desc-component {
    margin-inline: 5%;

    .right-content-details {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
    }

    .button-header {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        justify-content: space-between;

        @media (min-width : 769px) {
            flex-direction: row;
        }

        .button-header-title {
            color: #4D4D4D;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.25px;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 133.4%;

            @media (min-width :769px) {
                line-height: 123.5%;
                font-size: 30px;
            }
        }
    }

    .dr-details {
        color: black;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 157%;
        letter-spacing: 0.1px;
    }

    .details {
        color: black;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
    }

    .button-bar {
        display: flex;
        flex-direction: row;
        column-gap: 5px;

        @media(min-width : 769px) {
            column-gap: 24px;
        }

        .button-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 7px;
        }
    }

    .counts-text {
        color: #4D4D4D;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;

        @media (min-width: 769px) {
            font-size: 16px;
        }
    }

    .count-icon {
        width: auto;
        height: 20px;
    }

    .progressbar-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width : 769px) {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            column-gap: 20px;
        }
    }

    .progressbar-component {
        height: 15px;
        width: 100%;
        margin-bottom: 0px;
    }

    .progress-text {
        color: #4D4D4D;
        text-align: start;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .green-border-button {
        border-radius: 500px;
        border: 1.5px solid var(--primary-focus);
        display: flex;
        padding: 8px 36px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--Generic-White);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        background-color: var(--CCA-Green-Dark);
        width: min-content;
        cursor: pointer;
    }
}