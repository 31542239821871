.journal-list-component {
    .title-button-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    .icons-section {
        display: flex;
        flex-direction: row;
        gap: 18px;

        .icon-img {
            width: auto;
            height: 40px;
            cursor: pointer;
        }
    }
}