.refferal-list-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  margin: 20px 20px 20px 0px;
  border-radius: 7px;

  @media (max-width: 800px) {
    width: 100%;
  }

  .refferal-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 7px;
    padding: 10px;
    width: 70%;
    background-color: var(--Generic-White);
    position: relative;

    @media (max-width: 800px) {
      width: 90%;
    }
  }

  .refferal-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
  }

  .text-div {
    color: var(--neutral-900);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: bolder;
    line-height: 16px;
  }

  .badge {
    position: absolute;
    top: -8px;
    left: -5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--CCA-Maroon);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Generic-White);
    text-align: center;
    font-size: 600;
  }
}
