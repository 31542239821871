.cca-date-picker-component {
    background: white;
    .MuiFormControl-root {
        width: 300px;
        .MuiInputBase-root {
            height: 41px;
        }
        .MuiFormLabel-root {
            margin-top: -7px;
        }
    }
}