.explore-topic-page {
    display: flex;
    flex-direction: column;
    row-gap: 46px;

    .header-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 12px;

        @media(min-width: 769px) {
            flex-direction: row;
            align-items: flex-start;
        }

        .explore-title {
            color: var(--CCA-Green-Dark);
            font-family: Inter;
            font-size: 34px;
            font-style: normal;
            font-weight: 600;

            @media(min-width: 769px) {
                line-height: 120%;
                font-size: 48px;
                width: 100%;
            }
        }

        .explore-content {
            color: black;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .sub-topic-header {
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        justify-content: flex-start;
        align-items: center;
    }

    .sub-topic {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
    }

    .header-text {
        color: black;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;

        @media(min-width: 769px) {
            font-size: 34px;

        }
    }

    .sub-topic-icon {
        width: 32px;
        height: 32px;

        @media(min-width: 769px) {
            width: 40px;
            height: 40px;
        }

    }

    .explore-card-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
        width: 100%;

        @media(min-width: 600px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (min-width: 1024px) {
            grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
        }
    }
}