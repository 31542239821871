.individual-support-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    row-gap: 42px;
    position: relative;
    padding: 0 16px 30px;
    box-sizing: border-box;

    .support-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100%;
        padding: 0;
        box-sizing: border-box;

        @media (min-width: 547px) {
            width: 375px;
        }

        @media (min-width: 550px) {
            width: 400px;
        }

        @media (min-width: 728px) {
            width: 600px;
        }

        @media (min-width: 1024px) {
            width: 992px;
        }

        @media (min-width: 1440px) {
            width: 1250px;
        }
    }

    .title-section {
        display: flex;
        column-gap: 12px;
        align-items: center;

        img {
            height: 36px;
            width: auto;

            @media (min-width: 769px) {
                height: 48px;
            }
        }
    }

    .support-title {
        color: black;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 133.4%;

        @media (min-width: 547px) {
            font-size: 24px;
        }

        @media (min-width: 769px) {
            font-size: 36px;
            line-height: 116.7%;
        }
    }

    .slider-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        z-index: 2;

        @media (min-width: 769px) {
            gap: 53px;
        }

        .slider-button-icon {
            width: 28px;
            height: 28px;
            cursor: pointer;

            @media (min-width: 547px) {
                width: 32px;
                height: 32px;
            }

            @media (min-width: 769px) {
                width: 48px;
                height: 48px;
            }
        }
    }

    .swiper {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding: 0;
        overflow: visible;

        @media (min-width: 547px) {
            width: 375px;
        }

        @media (min-width: 550px) {
            width: 400px;
        }

        @media (min-width: 728px) {
            width: 600px;
        }

        @media (min-width: 1024px) {
            width: 992px;
        }

        @media (min-width: 1440px) {
            width: 1250px;
        }
    }

    .swiper-slide {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        @media (max-width: 546px) {
            padding: 0 8px;
        }
    }

    .swiper-wrapper {
        display: flex;
        align-items: stretch;
    }

    .swiper-horizontal {
        touch-action: pan-y !important;
        overflow: hidden;
    }

    .swiper-pagination-bullet {
        display: none !important;
    }
}