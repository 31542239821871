.expand-menu-component {
    background-color: var(--Generic-White);
    width: 100%;
    position: absolute;
    top: 65px;
    left: 0;
    z-index: 9999;

    @media (min-width : 769px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .sm-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .menu-grid {
        display: flex;
        margin: 36px auto;
        flex-direction: column;
        row-gap: 20px;

        @media (min-width : 550px) {
            max-width: 1048px;
            display: flex;
            flex-direction: row;
            justify-content: start;
        }

        @media (min-width : 1250px) {
            max-width: 1090px;
        }

        @media (min-width : 1400px) {
            max-width: 1250px;
        }
    }

    .menu-items {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        padding-left: 20px;

        .title {
            color: var(--CCA-Green-Dark);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
            letter-spacing: 0.15px;
            text-align: start;
            text-transform: uppercase;
        }
    }

    .options {
        color: black;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        cursor: pointer;
        padding: 0px 0px 0px 20px;

        @media (min-width : 769px) {
            padding: 0px;
        }
    }

    .dot-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
    }

    .dots-container {
        width: 20px;
        display: inline-block;
        text-align: center;
    }

    .dots {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: black;
        display: inline-block;
        margin-right: 10px;
    }

    .dots.hidden {
        visibility: hidden;
    }

    .dots.visible {
        visibility: visible;
    }

    .expand-menu-icon {
        display: block;
        padding-right: 20px;

        @media (min-width : 769px) {
            display: none;
        }
    }
}