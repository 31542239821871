.age-gate-page {
  .cca-date-picker-component {
    margin: 15px 12px;
  }

  .hsneIM {
    width: 237px !important;
    border-radius: 12rem !important;

    @media (min-width: 500px) {
      width: 340px !important;
      border-radius: 12rem !important;
    }
  }

  .date-picker {
    margin: 15px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .button-div {
    display: flex;
    flex-direction: row;
    margin: 10px 0 40px;
    column-gap: 10px;
  }
}
