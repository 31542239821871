.lesson-module-component {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 100%;
    height: 100%;

    .counter-section {
        display: flex;
        flex-direction: row;
        column-gap: 25px;
        align-items: center;
    }

    .counter-box {
        width: 20%;
    }

    .loader-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}