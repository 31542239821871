.service-component {
  display: flex;

  .sidebar {
    display: none;
  }

  .show-div {
    padding: 12px;
  }

  .cards-conatiner {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: 100%;
  }

  .container-space {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  .service-card-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 4px 4px 8px 0px #00000040;
  }

  .topic-title {
    margin: 0px 0px 0px 24px;
  }

  .service-card-box-img {
    height: 100%;
    width: 100%;
    display: flex;
    
  }

  .service-card {
    padding: 36px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    background-color: var(--Generic-White);
    width: 100%;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }

  .service-card span {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.05px;
    letter-spacing: -0.02em;
    text-align: left;
    padding: 0 25px;
  }
  .service-card p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    margin: 0px;
    padding: 0 25px;
  }
  .service-card button {
    margin: 30px 25px 0 25px;
  }
  .menu-list {
    padding: 32px 16px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    text-align: end;
    position: absolute;
    top: 0;
    right: 0%;
    width: 100%;
    background-color: var(--Generic-White);
    box-shadow: 0px 4px 8px 0px #00000040;
    z-index: 9;
  }

  .menu-items {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 31.12px;
    text-align: right;
    padding: 8px 0px;
  }

  .active-menu {
    border-right: 3px solid var(--CCA-Maroon);
    padding: 8px 18px;
    cursor: pointer;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 31.12px;
  }

  .service-div {
    margin: 40px 0px 10px;
    color: var(--CCA-Maroon);
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

@media (min-width: 426px) {
  .service-component {
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    width: 100%;

    .sidebar {
      display: block;
      flex-direction: column;
      row-gap: 12px;
      position: fixed;
      height: 100%;
      top: 0px;
    }
    .footer-component {
      position: relative;
    }
    .cards-conatiner {
      padding-left: 25%;
    }
    .menu-list {
      display: none;
    }
    .side-menu {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding-top: 120px;
      background: transparent;
    }

    .side-menu-logo {
      padding: 25px 0px 0px 17px;
      background-color: transparent;
    }

    .service-card-box {
      display: flex;
      flex-direction: row;
      border-radius: 20px;
    }
    .menu-items {
      text-align: start;
      border-left: 3px solid transparent;
      border-right: none;
      cursor: pointer;
    }
    .active {
      text-align: start;
      border-left: 3px solid transparent;
      padding: 8px 0px;
      cursor: pointer;
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 31.12px;
    }

    .service-card-box-img {
      width: 300px;
      height: auto;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    .large-green-button-disabled {
      width: 300px;
    }
    .large-green-button {
      width: 300px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .service-component {
    .cards-conatiner {
      padding-left: 25%;
    }
    .service-card-box {
      width: 100%;
    }
  }
}