.splash-screen-page {
    width: 100%;
    height: calc(100vh - 88px);
    overflow: hidden; 

    .slick-list {
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
    .slick-slide {
        img{
            height: 100vh;
            object-fit: cover;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 23%; 
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
    }

    .slick-dots li.slick-active button:before{
        color: var(--CCA-Green-Dark) !important;
        opacity: 1 !important;
        font-size: 8px;
    }

    .slick-dots li button:before {
        color: var(--Generic-White) !important;
        opacity: 1 !important;
        font-size: 8px;
    }
}
