.support-request-page {
  .radio-box-container {
    width: 85%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content: center;
    align-items: flex-start;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0px 0px 0px;

    @media (min-width: 900px) {
      width: 768px;
      margin: 40px 0px 0px 0px;
    }
  }

  .page-container {
    padding-top: 0px;
  }

  .options {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 5px;
  }

  .question-button-yes {
    width: auto;
    padding: 0 15px;

    @media (min-width: 426px) {
      width: 134px;
    }
  }

  @media (min-width: 900px) {
    .page-container {
      margin-top: 0;
      padding-bottom: 0px;
    }

    .title {
      width: 768px;
      font-size: 52px;
      line-height: 56px;
    }

    .question-button-div {
      margin-bottom: 30px;
    }
  }
}