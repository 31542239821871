.dropdown-menu-component {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .dropdown-menu {
        padding: 12px;
        border-radius: 12px;
        border: 1.5px solid var(--interactive-input, #CCD0DE);
        width: 50%;
        outline: none;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.75px;
        cursor: pointer;
    }
}