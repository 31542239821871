.exercise-input-component {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;

    .exercise-input {
        border-radius: 12px;
        border: 1.5px solid var(--interactive-input);
        background: var(--Generic-White);
        padding: 20px;
        font-size: 16px;
        outline: none;
    }
}