.hero-grid-component {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    width: 100%;

    @media (max-width : 800px) {
        .grid-image:nth-of-type(3) {
            display: none;
        }
    }

    .grid-image-response {
        width: 100%;
        height: auto;
        grid-column: span 2;
    }

    @media (min-width : 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;

        .grid-image-response {
            display: none;
        }
    }

    .grid-image {
        height: auto;
        width: 100%;

        @media (min-width : 728px) {
            width: 295px;
        }

        @media (min-width : 1024px) {
            width: 265px;
        }

        @media (min-width : 1280px) {
            width: 300px;
        }

        @media (min-width : 1440px) {
            width: 400px;
        }
    }

    .response-hide {
        @media (max-width : 1023px) {
            display: none;
        }
    }
}