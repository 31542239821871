.popular-topics-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 24px;

    .titlebar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .topic-title {
            color: var(--Black, #000);
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 133.4%;

            @media (min-width : 769px) {
                row-gap: 42px;
                font-size: 36px;
                font-style: normal;
                font-weight: 600;
                line-height: 116.7%;
            }
        }
    }

    .more-topics {
        display: flex;
        align-items: center;
        column-gap: 7px;
        cursor: pointer;
    }

    .arrow-text {
        color: var(--Black, #000);
        font-family: Inter;
        letter-spacing: 0.1px;
        font-size: 14px;
        font-weight: 500;
        line-height: 157%;

        @media (min-width : 769px) {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 133.4%;
        }
    }

    .left-side-img {
        @media (min-width : 769px) {
            max-height: 354px;
        }
    }
}