@import "../../../styles/colors.css";

.emergency-disclaimer-component {
  color: var(--CCA-Green-Dark);
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -1.04px;
  padding: 40px 10px;
}
