.lesson-sidebar-component {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .module-content {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        flex: 1;
    }

    .module-content-grid {
        padding: 36px;
        width: 100%;
    }

    .sidebar-section {
        border-right: 1px solid black;
        padding-left: 36px;
        width: 30%;
    }

    .mux-video {
        //150px = 90px(header) + 36px(padding) + 24px(row-gap)
        height: calc(100vh - 150px) !important;
    }
}