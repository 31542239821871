.back-module-component {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 5px;
    cursor: pointer;

    .navigation-icon {
        width: 24px;
        height: auto;
    }

    .navigation-title {
        color: var(--primary-main, #2B7272);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
    }
}