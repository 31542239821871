.request-options-page {

  .request-grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 5px;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 40px;

    .grid-item {
      flex: 1 1 calc(33.333% - 20px);
      box-sizing: border-box;
      max-width: calc(33.333% - 20px);
    }
  }

  .request-cards {
    background-color: var(--CCA-Light-Brown);
    width: 280px;
    height: 45px;
    text-align: center;
    border: 2px solid var(--CCA-Brown);
    border-radius: 5px;
    font-weight: 600;
    font-family: Inter;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 3px;
  }

  @media (min-width: 700px) {
    .request-grid-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      max-width: 780px;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      .grid-item {
        flex: 1 1 calc(33.333% - 20px);
        box-sizing: border-box;
        max-width: calc(33.333% - 20px);
      }
    }
  }

  @media (max-width: 426px) {
    .request-card-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}